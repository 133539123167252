import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';

import { useSettingsContext } from '@providers/settings-provider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDebounce } from '@hooks';

const REPORTS_SHOW_DELAY = 600;
export const reportsList = {
  'Credits': 'Credits',
  'Credits graph': 'Credits graph',
  'Current Pipeline': 'Current Pipeline',
  'allocation-report': 'Credit Allocation Goals Tracker',
  'allocation-revenue-goals': 'Credit Allocation And Revenue Goals',
  'Admin Login Counter': 'Admin Login Counter',
  'Calls': 'Overdue Calls',
  'New Completed Files': 'New Completed Files',
  'New Documents Uploaded': 'New Documents Uploaded',
  'par-credits': 'Delayed Records',
  'Verqor\'s reach to women': 'Verqor\'s reach to women',
  'Registrations - gender': 'Registrations - gender',
  'Clients - gender': 'Clients - gender',
  'Contact Us entries': 'Contact Us entries',
  'visitors-report': 'Visitors / Effective registrations rate Graph',
  'new-registrations': 'New Registrations',
  'register-credit-progress': 'Registrations / Completed File / Apply - Rates',
  'user-crops': 'User Crops by Category',
  'credit-crops': 'Financed Crops by Category',
  'user-crops-by-crop': 'User crops by Crop',
  'credit-crops-by-crop': 'Financed crops by Crop',
  'financial-ranking': 'Financial Institution Ranking',
  'investor-reports-progress': 'Investor Reports - Progress',
};
export type ReportType = keyof typeof reportsList;

export const ReportsModal = () => {
  const {
    reportsOpened, setReportsOpened, admin, refreshAdmin, updateAdmin,
  } = useSettingsContext();
  const [reports, setReports] = useState<ReportType[]>(admin?.reports || []);
  const cls = useStyles();
  const debouncedReports = useDebounce((admin?.reports || []).join(''), REPORTS_SHOW_DELAY);

  useEffect(() => {
    reportsOpened && refreshAdmin();
  }, [reportsOpened]);

  useEffect(() => {
    setReports(admin?.reports || []);
  }, [debouncedReports]);

  const toggleColumn = async (reportKey: ReportType) => {
    const nextReports = reports.includes(reportKey)
      ? reports.filter(report => report !== reportKey)
      : reports.concat([reportKey]);
    setReports(nextReports);
    await updateAdmin({ reports: nextReports });
  };

  const onModalClose = () => void setReportsOpened(false);

  return (
    <div className={cls.box}>
      <Modal
        open={reportsOpened}
        onClose={onModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={cls.modal}>
          <div className={cls.modalTitle}>Reports</div>
          <FormGroup className={cls.columns} row>
            {Object.entries(reportsList).map(([reportKey, reportTitle]) => {
              const handleChange = () => toggleColumn(reportKey as ReportType);
              return (
                <FormControlLabel
                  key={reportKey}
                  className="checkbox"
                  control={
                    <Checkbox
                      color="secondary"
                      checked={reports.includes(reportKey as ReportType)}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={reportTitle}
                />
              );
            })}
          </FormGroup>
          <Button
            onClick={onModalClose}
            className={cls.saveBtn}
            type="submit"
            color="warning"
            variant="contained"
            size="large"
          >Apply</Button>
        </Box>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative',
  },
  modal: {
    padding: '8px 44px 16px',
    position: 'absolute',
    top: 50,
    left: '50%',
    transform: 'translate(-50%, 50px)',
    width: 1000,
    border: '2px solid #042E6B',
    borderRadius: 10,
    background: '#FFF',
  },
  modalTitle: {
    margin: '5px 0 5px',
    color: '#042E6B',
    fontSize: 15,
    fontWeight: 400,
  },
  columns: {
    '& label': {
      display: 'flex',
      marginRight: 15,
      width: 220,
      lineHeight: 15,
      userSelect: 'none',
    },
  },
  saveBtn: {
    height: 35,
    borderRadius: 8,
    background: '#FD8A32',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 11,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 30,
    marginTop: 15,
  },
});
