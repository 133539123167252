import { Http } from './http';
import { Contract } from '@types';

class ContractHttp extends Http {
  prefix = '/contract';

  async create(params: { creditId: number }): Promise<Contract> {
    const res = await this.post<Contract>('', params);

    return res.data;
  }

  async update(params: Contract): Promise<Contract> {
    const res = await this.put<Contract>(`/${params.id}`, params);

    return res.data;
  }
}

export const contractHttp = new ContractHttp();
