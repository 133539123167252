import { clsx } from 'clsx';
import { Checkbox } from '@mui/material';
import { DateInput, useInput } from 'react-admin';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';

import { inputParser } from '@utils';
import { Contract, Guarantor } from '@types';
import { InitialCard, InitialRow, Loader, TextInput } from '@components';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import cls from './GuarantorsForm.module.css';

const CarouselIterator = ({ source, isRequired }: { source: string; isRequired: boolean }) => {
  const labelSize = 9;
  const { field } = useInput({ source });

  return (
    <Swiper
      centeredSlides={true}
      spaceBetween={0}
      slidesPerView={1}
      keyboard={{ enabled: true }}
      navigation={true}
      modules={[Keyboard, Navigation, Pagination]}
      className={cls.carousel}
      pagination={{ type: 'fraction' }}
      noSwiping
      touchStartPreventDefault={false}
      simulateTouch={false}
    >
      {field?.value?.length ? field.value.map((c: Guarantor, index: number) => (
        <SwiperSlide key={c.id} virtualIndex={c.id} className={clsx(!isRequired && cls.blur)}>
          <InitialRow title="Full Name" fontLg labelSize={labelSize}>
            <TextInput
              source={`${source}[${index}].fullName`}
              inputProps={{ maxLength: 30 }}
              parse={inputParser.onlyEngLetters}/>
          </InitialRow>
          <InitialRow title="Birthdate/DOC (G)" fontLg labelSize={labelSize}>
            <DateInput source={`${source}[${index}].birthdate`} label="" className={cls.dateInput}/>
          </InitialRow>
          <InitialRow title="No. Filing Cert (G)" fontLg labelSize={labelSize}>
            <TextInput
              source={`${source}[${index}].noFillingCert`}
              inputProps={{ maxLength: 13 }}/>
          </InitialRow>
          <InitialRow title="RFC (G)" fontLg labelSize={labelSize}>
            <TextInput
              source={`${source}[${index}].rfc`}
              inputProps={{ maxLength: 13 }}/>
          </InitialRow>
          <InitialRow title="Address(G)" fontLg labelSize={labelSize}>11111</InitialRow>
          <InitialRow title="Phone(G)" fontLg labelSize={labelSize}>{c.phone}</InitialRow>
          <InitialRow title="Email(G)" fontLg labelSize={labelSize}>{c.email}</InitialRow>
        </SwiperSlide>
      )) : (
        <SwiperSlide key={0} virtualIndex={0} className={clsx(!isRequired && cls.blur)}>
          <InitialRow title="Full Name" fontLg labelSize={labelSize}></InitialRow>
          <InitialRow title="Birthdate/DOC (G)" fontLg labelSize={labelSize}> </InitialRow>
          <InitialRow title="No. Filing Cert (G)???" fontLg labelSize={labelSize}></InitialRow>
          <InitialRow title="RFC (G)" fontLg labelSize={labelSize}></InitialRow>
          <InitialRow title="Address(G)" fontLg labelSize={labelSize}></InitialRow>
          <InitialRow title="Phone(G)" fontLg labelSize={labelSize}></InitialRow>
          <InitialRow title="Email(G)" fontLg labelSize={labelSize}></InitialRow>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export const GuarantorsForm = ({ data }: { data: Contract }) => {
  return !data ? <Loader /> : (
    <InitialCard
      wrapperClass={clsx(cls.card)}
      left={
        <div>
          <span>Guarantor</span>
          <Checkbox style={{ width: 40 }} size="medium" disabled checked={!data.isGuarantorsRequired}/>
        </div>
      }
      extraPadding>
      <div className={cls.box}>
        <CarouselIterator source="guarantors" isRequired={data.isGuarantorsRequired}/>
        {!data.isGuarantorsRequired
          ? (
            <div className={cls.overlay}>
              <div className={cls.popup}>
                This credit will not be needing a Guarantor
              </div>
            </div>) : null}
      </div>
    </InitialCard>
  );
};
