import { Button } from '@mui/material';
import { Edit, EditProps, SimpleForm } from 'react-admin';

import { Contract } from '@types';
import { Progress } from './Progress';
import { ContractsForm } from './ContractsForm';
import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { ExportIcon, Forbidden, InitialPage } from '@components';
import cls from './Contracts.module.css';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Contract: {record.name}</span>) : null;

export const ContractsEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacEdit = useRbacEdit();
  const record = useRecord<Contract>(props);

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <InitialPage
        title="Individual Contract"
        backTo="/contract"
        backText="Back to all contracts"
        headerCenter={<Progress options={{ labels: ['step 1', 'step 2', 'step 3', 'step 4'] }} active={1} />}
        className={cls.contractInitPage}
        headerRight={
          <>
            <Button
              variant="outlined"
              color="inherit"
              className={cls.exportBtn}
              onClick={undefined}
              disabled={true}
            ><ExportIcon/>Export</Button>
            <div className={cls.code}>{record?.creditCode || ''}</div>
          </>
        }
      >
        <SimpleForm toolbar={false}>
          <ContractsForm record={record} />
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
