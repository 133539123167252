import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useNotify, useRedirect } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { FilePdfFilled, SaveFilled } from '@ant-design/icons';

import { Contract } from '@types';
import { GuarantorsForm } from '@pages/contracts/GuarantorsForm';
import { dateFormatter, inputParser, moneyFormatter } from '@utils';
import { CreditStatusField } from '@pages/credit/CreditStatusField';
import {
  ConfirmDelete,
  Container,
  DateInput,
  InitialCard,
  InitialRow,
  Loader,
  NumberInput,
  TextInput,
} from '@components';
import { contractHttp } from '@network';
import cls from './ContractsForm.module.css';

export const ContractsForm = ({ record }: { record?: Contract }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const redirect = useRedirect();
  const notify = useNotify();
  const { getValues } = useFormContext<Contract>();
  const values = getValues();
  const labelSize = 9;

  const handleUpdateContract = async (): Promise<void> => {
    try {
      await contractHttp.update(values);
      notify('Contract was updated successfully.');
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = () => {
    redirect('/contract/');
  };

  useEffect(() => {
    if (record) {
      setLoading(false);
    }
  }, [record]);

  return !loading && record ? (
    <Container max>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24}>
          <InitialCard left="General Info" extraPadding>
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={12}>
                <InitialRow title="Credit" fontLg labelSize={labelSize}>
                  {record.creditCode}
                </InitialRow>
                <InitialRow title="Accredited" fontLg labelSize={labelSize}>
                  {record.accredited}
                </InitialRow>
                <InitialRow title="Birthdate/DOC" fontLg labelSize={labelSize}>
                  {dateFormatter.toDateSlash(record.userBirthday)}
                </InitialRow>
                <InitialRow title="No. Filing Cert" fontLg labelSize={labelSize}>
                  <TextInput source="noFillingCert" inputProps={{ maxLength: 100 }} autoFocus/>
                </InitialRow>
                <InitialRow title="Legal Rep*" fontLg labelSize={labelSize}>
                  <TextInput source="userFullname" inputProps={{ maxLength: 100 }}/>
                </InitialRow>
                <InitialRow title="RFC" fontLg labelSize={labelSize}>
                  {record.rfc}
                </InitialRow>
              </Col>
              <Col xs={24} lg={12}>
                <InitialRow title="Accredited Bank" fontLg labelSize={labelSize}>
                  <TextInput source="accreditedBank" inputProps={{ maxLength: 30 }}/>
                </InitialRow>
                <InitialRow title="Account holder" fontLg labelSize={labelSize}>
                  <TextInput source="accountHolder" inputProps={{ maxLength: 100 }}/>
                </InitialRow>
                <InitialRow title="CLABE" fontLg labelSize={labelSize}>
                  <TextInput
                    source="clabe"
                    inputProps={{ maxLength: 18 }}
                    parse={inputParser.onlyNumbers}
                  />
                </InitialRow>
                <InitialRow title="Address" fontLg labelSize={labelSize}>
                  {record.userAddress}
                </InitialRow>
                <InitialRow title="Phone" fontLg labelSize={labelSize}>
                  {record.userPhone}
                </InitialRow>
                <InitialRow title="Email" fontLg labelSize={labelSize}>
                  {record.userEmail}
                </InitialRow>
              </Col>
            </Row>
          </InitialCard>
        </Col>
        <Col xs={24} xl={12}>
          <GuarantorsForm data={record} />
        </Col>
        <Col xs={24} xl={12}>
          <InitialCard left="Other Data" extraPadding>
            <InitialRow title="Membership Rate" fontLg labelSize={labelSize}>
              {record.membershipRate}
            </InitialRow>
            <InitialRow title="Disposal Fee" fontLg labelSize={labelSize}>
              <NumberInput source="disposalFee" />
            </InitialRow>
            <InitialRow title="Date" fontLg labelSize={labelSize}>
              <DateInput source="date" label="" />
            </InitialRow>
            <InitialRow title="Place" fontLg labelSize={labelSize}>
              <TextInput source="place" inputProps={{ maxLength: 100 }} />
            </InitialRow>
            <InitialRow title="Description of pledged assets" fontLg labelSize={labelSize}>
              <TextInput source="description" inputProps={{ maxLength: 150 }} />
            </InitialRow>
            <InitialRow title="Contract Status" fontLg labelSize={labelSize}>
              <CreditStatusField source="status" />
            </InitialRow>
          </InitialCard>
        </Col>
        <Col xs={24} sm={24}>
          <InitialCard left="Credit Info" extraPadding>
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={12}>
                <InitialRow title="CAT" fontLg labelSize={labelSize}>
                  {record.interestRateCat}
                </InitialRow>
                <InitialRow title="Interest Rate" fontLg labelSize={labelSize}>
                  {record.interestRate}
                </InitialRow>
                <InitialRow title="Moratory Rate" fontLg labelSize={labelSize}>
                  {record.moratoryRate}
                </InitialRow>
                <InitialRow title="Credit Amount" fontLg labelSize={labelSize}>
                  <NumberInput source="creditAmount" />
                  {/*<NumberInput source="creditAmount" validate={validateRequestAmount} />*/}
                </InitialRow>
                <InitialRow title="Payment Amount" fontLg labelSize={labelSize}>
                  {moneyFormatter.format(record.paymentAmount)}
                </InitialRow>
                <InitialRow title="Credit Term" fontLg labelSize={labelSize}>
                  {record.creditTerms}
                </InitialRow>
              </Col>
              <Col xs={24} lg={12}>
                <InitialRow title="Signature Date" fontLg labelSize={labelSize}>
                  <DateInput source="signatureDate" label="" />
                </InitialRow>
                <InitialRow title="Contract Term" fontLg labelSize={labelSize}>
                  {record.contractTerms}
                </InitialRow>
                <InitialRow title="Payment Date" fontLg labelSize={labelSize}>
                  <DateInput source="paymentDate" label="" />
                </InitialRow>
                <InitialRow title="Use of Credit" fontLg labelSize={labelSize}>
                  <TextInput source="useOfCredit" inputProps={{ maxLength: 100 }} />
                </InitialRow>
                <InitialRow title="Drawdowns" fontLg labelSize={labelSize}>
                  <TextInput
                    source="drawdowns"
                    inputProps={{ maxLength: 18 }}
                    parse={inputParser.onlyNumbers}
                  />
                </InitialRow>
                <InitialRow title="Promissory Rate" fontLg labelSize={labelSize}>
                  {record.promissoryRate}
                </InitialRow>
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={12} md={8} className={cls.actionsLeft}>
          <ConfirmDelete title="Are you sure?" cb={handleCancel}>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.cancelBtn)}
              onClick={undefined}
            >Go back</Button>
          </ConfirmDelete>
        </Col>
        <Col xs={12} md={16} className={cls.actionsRight}>
          <Button
            variant="outlined"
            color="inherit"
            className={clsx(cls.action, cls.saveBtn)}
            onClick={handleUpdateContract}
          ><SaveFilled className={cls.actionIcon}/>Save and Sync</Button>
          <Button
            variant="outlined"
            color="inherit"
            className={clsx(cls.action, cls.completeBtn)}
            onClick={undefined}
          ><FilePdfFilled className={cls.actionIcon}/>Complete Contract</Button>
        </Col>
      </Row>
    </Container>
  ) : <Loader center/>;
};
